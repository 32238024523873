<template>
  <div>
      <b-card no-body>
      <b-card-body>
      
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>